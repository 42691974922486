/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account';
import type { AccountCampaigns } from '../models/AccountCampaigns';
import type { AccountGrossRaised } from '../models/AccountGrossRaised';
import type { AccountRecurringPlanSummary } from '../models/AccountRecurringPlanSummary';
import type { BankAccountDetail } from '../models/BankAccountDetail';
import type { GetAccountDonationBoostsDto } from '../models/GetAccountDonationBoostsDto';
import type { GetAccountFinancialRaised } from '../models/GetAccountFinancialRaised';
import type { GetAccountTotalRaised } from '../models/GetAccountTotalRaised';
import type { GetFinancialAnalytics } from '../models/GetFinancialAnalytics';
import type { GetFinancialSummary } from '../models/GetFinancialSummary';
import type { LatestPayout } from '../models/LatestPayout';
import type { PaginatedRecurringPlansSubscribers } from '../models/PaginatedRecurringPlansSubscribers';
import type { PayoutList } from '../models/PayoutList';
import type { RecurringPlan } from '../models/RecurringPlan';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { GetReferralsStatsByAccountIdEntity } from '../models/GetReferralsStatsByAccountIdEntity';
export class AccountService {
  /**
   * Returns list of accounts of the logged in user
   * @returns Account Successful response
   * @throws ApiError
   */
  public static accountGetAccountsV4(
    userId?: string,
  ): CancelablePromise<Array<Account>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts',
      query: {
        userId: userId,
      },
    });
  }
  /**
   * Returns payouts list of account
   * @param currencyCode The CurrencyCode of the account
   * @param id The ID of the account
   * @param skip Number of records to skip
   * @param take Number of records to show
   * @param to The end date for the payouts list in yyyy-mm-dd format
   * @param from The start date for the payouts list in yyyy-mm-dd format
   * @returns PayoutList Successful response
   * @throws ApiError
   */
  public static accountGetPayoutsV4(
    currencyCode: 'USD' | 'CAD' | 'GBP',
    id: number,
    skip?: number,
    take?: number,
    to?: string,
    from?: string,
  ): CancelablePromise<PayoutList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/payouts',
      path: {
        id: id,
      },
      query: {
        skip: skip,
        take: take,
        to: to,
        from: from,
        currencyCode: currencyCode,
      },
    });
  }
  /**
   * Returns financial summary of account
   * @param currencyCode The CurrencyCode of the account
   * @param id The ID of the account
   * @param to The end date for the summary in yyyy-mm-dd format
   * @param from The start date for the summary in yyyy-mm-dd format
   * @param campaignStatus The status of the campaign
   * @returns GetFinancialSummary Successful response
   * @throws ApiError
   */
  public static accountGetFinancialSummaryV4(
    currencyCode: 'USD' | 'CAD' | 'GBP',
    id: number,
    to?: string,
    from?: string,
    campaignStatus?: number,
    userId?: string,
  ): CancelablePromise<GetFinancialSummary> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/financials',
      path: {
        id: id,
      },
      query: {
        to: to,
        from: from,
        campaignStatus: campaignStatus,
        currencyCode: currencyCode,
        userId: userId,
      },
    });
  }
  /**
   * Returns total raised amount for an account
   * @param currencyCode The CurrencyCode of the account
   * @param id The ID of the account
   * @returns GetAccountTotalRaised Successful response
   * @throws ApiError
   */
  public static accountGetAccountTotalRaisedV4(
    currencyCode: 'USD' | 'CAD' | 'GBP',
    id: number,
  ): CancelablePromise<GetAccountTotalRaised> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/total-raised',
      path: {
        id: id,
      },
      query: {
        currencyCode: currencyCode,
      },
    });
  }
  /**
   * Returns latest payout of the provided account
   * @param currencyCode The CurrencyCode of the account
   * @param id The ID of the account
   * @returns LatestPayout Successful response
   * @throws ApiError
   */
  public static accountGetLatestPayoutV4(
    currencyCode: 'USD' | 'CAD' | 'GBP',
    id: number,
  ): CancelablePromise<LatestPayout> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/payouts/latest',
      path: {
        id: id,
      },
      query: {
        currencyCode: currencyCode,
      },
    });
  }
  /**
   * Returns total financial details of an account
   * @param currencyCode The CurrencyCode of the account
   * @param id The ID of the account
   * @returns GetAccountFinancialRaised Successful response
   * @throws ApiError
   */
  public static accountGetAccountTotalFinancialsV4(
    currencyCode: string,
    id: number,
  ): CancelablePromise<GetAccountFinancialRaised> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/finances',
      path: {
        id: id,
      },
      query: {
        currencyCode: currencyCode,
      },
    });
  }
  /**
   * Returns financial analytics of account
   * @param id The ID of the account
   * @param from The start date in yyyy-mm-dd format
   * @param to The end date in yyyy-mm-dd format
   * @param currencyCode
   * @param timeZone
   * @returns GetFinancialAnalytics Successful response
   * @throws ApiError
   */
  public static accountGetFinancialAnalyticsV4(
    id: number,
    from: string,
    to: string,
    currencyCode: 'USD' | 'CAD' | 'GBP',
    timeZone: string,
  ): CancelablePromise<GetFinancialAnalytics> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/financial-analytics',
      path: {
        id: id,
      },
      query: {
        from: from,
        to: to,
        currencyCode: currencyCode,
        timeZone: timeZone,
      },
    });
  }
  /**
   * Returns list of bank account details of the accountId
   * @param id The ID of the account
   * @returns BankAccountDetail Successful response
   * @throws ApiError
   */
  public static accountGetBankAccountDetailsV4(
    id: number,
  ): CancelablePromise<Array<BankAccountDetail>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/bank-details',
      path: {
        id: id,
      },
    });
  }
  /**
   * Returns a list of campaigns associated with the account
   * @param id The ID of the account
   * @param status Filter campaigns by status (active, closed)
   * @returns AccountCampaigns List of campaigns associated with the account
   * @throws ApiError
   */
  public static accountGetCampaignsV4(
    id: number,
    status?: 'active' | 'closed' | 'closed_not_paid',
  ): CancelablePromise<Array<AccountCampaigns>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/campaigns',
      path: {
        id: id,
      },
      query: {
        status: status,
      },
    });
  }
  /**
   * Returns gross raised of an account against a currency
   * @param id The ID of the account
   * @param challengeType
   * @param currencyCode
   * @returns AccountGrossRaised Gross raised of an account
   * @throws ApiError
   */
  public static accountGetAccountGrossRaisedByChallengeV4(
    id: number,
    challengeType: 'ramadan1445' | 'ramadan1444',
    currencyCode: 'USD' | 'CAD' | 'GBP',
  ): CancelablePromise<AccountGrossRaised> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/gross-raised',
      path: {
        id: id,
      },
      query: {
        challengeType: challengeType,
        currencyCode: currencyCode,
      },
    });
  }
  /**
   * Returns challenge typed boosts of an account against a currency
   * @param id The ID of the account
   * @param currencyCode
   * @param challengeType
   * @returns GetAccountDonationBoostsDto Challenge type boosts of an account
   * @throws ApiError
   */
  public static accountGetAccountDonationBoostsV4(
    id: number,
    currencyCode: 'USD' | 'CAD' | 'GBP',
    challengeType: 'ramadan1445' | 'ramadan1444',
  ): CancelablePromise<GetAccountDonationBoostsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/donations/{challengeType}/boosts',
      path: {
        id: id,
        challengeType: challengeType,
      },
      query: {
        currencyCode: currencyCode,
      },
    });
  }
  /**
   * Returns Referrals Stats by Account ID
   * @param id
   * @returns GetReferralsStatsByAccountIdEntity Referrals Stats by Account ID
   * @throws ApiError
   */
  public static accountGetReferralsStatsByAccountIdV4(
    id: number,
  ): CancelablePromise<GetReferralsStatsByAccountIdEntity> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/referrals/stats',
      path: {
        id: id,
      },
    });
  }
  /**
   * Returns Referrals by Account ID
   * @param id
   * @param skip
   * @param take
   * @returns GetReferralsStatsByAccountIdEntity Referrals by Account ID
   * @throws ApiError
   */
  public static accountGetAllReferralsByAccountIdV4(
    id: number,
    skip?: number,
    take?: number,
  ): CancelablePromise<GetReferralsStatsByAccountIdEntity> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/referrals',
      path: {
        id: id,
      },
      query: {
        skip: skip,
        take: take,
      },
    });
  }
  /**
   * Returns recurring plans
   * @param id
   * @returns RecurringPlan Recurring plans for an account
   * @throws ApiError
   */
  public static accountGetRecurringPlansV4(
    id: number,
  ): CancelablePromise<Array<RecurringPlan>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/recurring-plans',
      path: {
        id: id,
      },
    });
  }
  /**
   * Returns recurring plans associated with an account
   * @param id
   * @returns AccountRecurringPlanSummary Recurring plans summary for an account
   * @throws ApiError
   */
  public static accountGetRecurringPlansSummaryV4(
    id: number,
  ): CancelablePromise<AccountRecurringPlanSummary> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/recurring-plans/summary',
      path: {
        id: id,
      },
    });
  }
  /**
   * Returns recurring plans subscribers associated with an account
   * @param id
   * @param take Number of records to take
   * @param skip Number of records to skip
   * @param status Filter by status, either active or in-active
   * @param orderBy Order by asc or desc
   * @param email Filter by email address
   * @param name Filter by name
   * @param userId Filter by user ID
   * @returns PaginatedRecurringPlansSubscribers Recurring plans subscribers for an account
   * @throws ApiError
   */
  public static accountGetRecurringPlansSubscribersV4(
    id: number,
    take?: number,
    skip?: number,
    status?: 'active' | 'inactive',
    orderBy?: 'asc' | 'desc',
    email?: string,
    name?: string,
    userId?: number,
  ): CancelablePromise<PaginatedRecurringPlansSubscribers> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/accounts/{id}/recurring-plans/subscribers',
      path: {
        id: id,
      },
      query: {
        take: take,
        skip: skip,
        status: status,
        orderBy: orderBy,
        email: email,
        name: name,
        userId: userId,
      },
    });
  }
}
